import React from "react"

import Layout from "../../components/layout"
import { FooterAlternate1 } from "../../components/footer"
import SEO from "../../components/seo"

const IndexPage = ({ location }) => (
    <Layout location={location}>
      <SEO title="The library" />

                      
<header className="header header--basic ">
    <div className="header__content">
        <div className="container">
            <div className="row">
                <div className="header__inner col-md-9 col-lg-7 col-lg-offset-1">
                    <h3 className="header__category">Knowledge centre</h3>
                    <h1 className="header__title">The library</h1>
                    <h3 className="header__caption">The most highly recommended books providing practical guidance to sales professionals.</h3>
                </div>
            </div>
        </div>
    </div>
</header>
<div className="listing listing--base">

<div className="listing__item">
    <div className="container">
        <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-offset-1">
                <img alt="The Future of the Sales Profession" className="listing__image" height="506" src="../../media/images/The-future.width-350.jpg" width="350"/>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-5">
                <h2 className="listing__title">The Future of the Sales Profession</h2>
                <p className="listing__author">Graham Hawkins</p>
                <p className="listing__price">$29.95</p>
                <div className="listing__buttons">
                    <a href="http://www.transformsales.com.au/product/the-future-of-the-sales-profession/" className="btn btn-default">Buy from Transform Sales International</a>
                </div>
                <div className="listing__copy">
                    <div className="rich-text"><p>Product lifecycles are getting shorter, sales cycles are getting longer, there are more competitors entering the market, and buyers are doing most of their research online before they even call you. When you finally get the meeting, buyers only want your best price. </p><p>Despite all of this, your manager keeps asking for more – more calls, more meetings, faster, faster, faster! You’re stuck between a rock and a hard place – a more challenging sales environment than ever before on one side, and ever-increasing quotas and expectations on the other. </p><p>How will you respond? Wait and see how it all unfolds? Or fight for your career and your livelihood? </p><p>In The Future of the Sales Profession, sales leader Graham Hawkins shares the cold, hard truths about the new realities facing the sales profession, and how you can protect and enhance your career.</p></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="listing__item is-odd">
    <div className="container">
        <div className="row">
            <div className="col-xs-12 col-md-6 col-md-push-6 col-lg-5">
                <img alt="Sales Transformation" className="listing__image" height="498" src="../../media/images/book-thumb-01.width-350.jpg" width="350"/>
            </div>
            <div className="col-xs-12 col-md-6 col-md-pull-6 col-lg-5 col-lg-pull-4">
                <h2 className="listing__title">Sales Transformation</h2>
                <p className="listing__author">Graham Hawkins</p>
                <p className="listing__price">$24.95</p>
                <div className="listing__buttons">
                    <a href="http://www.transformsales.com.au/product/sales-transformation/" className="btn btn-default">Buy from Transform Sales International</a>
                </div>
                <div className="listing__copy">
                    <div className="rich-text"><p>B2B sales has not changed in over 130 years. Yet, many sales leaders seem unaware of the rapidly changing nature of buyer behaviour. Vendors are so focused on short term revenue results, and how they line up against their competitors, that they are unwittingly failing the first major test of business – understanding market trends and customer requirements.</p><p>This book has one purpose: to create a <em>point in time</em> snapshot of all known factors currently impacting vendor sales teams. The research and analysis conducted shines a light on a period of massive upheaval and change that now characterizes the current global outlook for technology vendors. Vendors must now be willing to break with tradition and make some important changes that begin to address the <em>new buying journey, </em>and this book explains how.</p><p><br/></p></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="listing__item">
    <div className="container">
        <div className="row">
            <div className="col-xs-12 col-md-6 col-lg-offset-1">
                <img alt="Rebirth of the Salesman" className="listing__image" height="525" src="../../media/images/rebirth-of-the-salesman.width-350.jpg" width="350"/>
            </div>
            <div className="col-xs-12 col-md-6 col-lg-5">
                <h2 className="listing__title">Rebirth of the Salesman</h2>
                <p className="listing__author">Cian Mcloughlin</p>
                <p className="listing__price">$24.95</p>
                <div className="listing__buttons">
                    <a href="http://amzn.to/2m7wyMd" className="btn btn-default">Buy from Amazon</a>
                </div>
                <div className="listing__copy">
                    <div className="rich-text"><p>In his book, Cian provides a blueprint to help sales professionals not just survive, but thrive in this brave new world of business-to-business selling. Each chapter hones in on a specific character trait and behaviour for achieving sales mastery, providing concrete steps that any salesperson can follow, to develop their sales effectiveness and enhance their personal brand.</p></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="listing__item is-odd">
    <div className="container">
        <div className="row">
            <div className="col-xs-12 col-md-6 col-md-push-6 col-lg-5">
                <img alt="The Joshua Principle" className="listing__image" height="519" src="../../media/images/Joshua.width-350_yaTw9Pn.jpg" width="350"/>
            </div>
            <div className="col-xs-12 col-md-6 col-md-pull-6 col-lg-5 col-lg-pull-4">
                <h2 className="listing__title">The Joshua Principle</h2>
                <p className="listing__author">Tony J. Hughes</p>
                <p className="listing__price">$24.95</p>
                <div className="listing__buttons">
                    <a href="https://www.amazon.com/Joshua-Principle-Leadership-Secrets-Selling-ebook/dp/B00C9GF4OU" className="btn btn-default">Buy from Amazon</a>
                </div>
                <div className="listing__copy">
                    <div className="rich-text"><p>Joshua Peters is a salesman in crisis – after losing a key deal his boss threatens him with the sack and he has doubts concerning his choice of career. His father is a sales veteran who progressed all the way to CEO but with their relationship at an all time low he struggles to help. Then a mentor’s invitation from the other side of the world powerfully transforms everything as Joshua embarks on the journey of discovering leadership secrets of strategic selling. He applies the principles to the biggest and most complex deal of his life and his mentorship culminates with a powerful meeting that finally reveals The Joshua Principle. </p><p>Learn about the Value Quadrant for Professional Sales Agents©, The New ROI©, the seven sins of selling, the ten laws of relationship and strategic selling, how to develop and execute effective strategy, the history and evolution of professional selling, how to gain insight to challenge thinking and create business value, how to successfully sell at the top, and much more.</p></div>
                </div>
            </div>
        </div>
    </div>
</div>

</div>
      
      <FooterAlternate1 />

    </Layout>
)

export default IndexPage 